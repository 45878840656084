// import React from 'react';
import {Route, Routes, BrowserRouter} from "react-router-dom"
import Check from './pages/check';
import './App.css';
import CheckIn from "./pages/checkin";
import CheckOut from "./pages/checkout";

const App = () => {
  return (
    <>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Check />} index />
          <Route path="/checkin" element={<CheckIn />} />
          <Route path="/checkout" element={<CheckOut />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
