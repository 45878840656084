import React from "react";

const CheckInInput = ({
  title,
  subTitle,
  type,
  required,
  disabled,
  inputWidth,
  placeholder,
  defaultValue,
  error,
  name,
  value,
  min,
  onChange,
}) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };

  return (
    <div className="w-full flex justify-between items-center sp:items-start text-lg sp:flex-col sp:gap-y-2 xmin:text-sm">
      <div className=" font-semibold sp:flex sp:items-center">
        <div className="flex items-center gap-3">
          {required && (
            <p className=" w-12 h-10 flex justify-center items-center rounded-lg bg-[#545454] text-white text-sm sp:w-8 sp:h-7 sp:text-xs">
              必須
            </p>
          )}
          <p>{title}</p>
        </div>
        <p>{subTitle}</p>
      </div>
      <div className="sp:w-full">
        <input
          type={type}
          className={`${inputWidth ? `w-[300px]` : `w-[400px] sp:w-full`
            } h-11 bg-[#eff1f5] p-3 rounded-md`}
          disabled={disabled}
          placeholder={placeholder}
          defaultValue={defaultValue}
          min={min}
          onChange={handleChange}
          value={value}
        />
        {error && <p className="ps-2 text-red-600 text-sm">{error[name]}</p>}
      </div>
    </div>
  );
};

export default CheckInInput;
