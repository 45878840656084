import React, { useEffect, useState } from "react";
import SelectBox from "../components/selectBox";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "antd";
import axios from "axios";
import { BUILDING_ENDPOINT, CONTACT_ENDPOINT } from "../endpoint";

const CheckOut = () => {
  const [build, setBuild] = useState([]);
  const [roomNum, setRoomNum] = useState();
  let navigate = useNavigate();

  const routeChange = () => {
    navigate("/");
  };

  useEffect(() => {
    try {
      const getBuildingData = async () => {
        const res = await axios.get(BUILDING_ENDPOINT);
        setBuild(res.data);
      };
      getBuildingData();
    } catch (error) {
      console.log(error);
    }
  }, []);

  const handleCheckout = () => {
    // try {
    //   return axios.put(CONTACT_ENDPOINT, roomNum);
    // } catch (error) {
    //   console.log(error);
    // }
    routeChange();
  }

  return (
    <section>
      <div className="max-w-[800px] px-5 mx-auto h-screen flex flex-col justify-center items-center gap-32 sp:gap-20 xmin:gap-10">
        <div className="w-full flex sp:flex-col gap-8 xmin:gap-4 justify-center items-center">
          <p className="text-center w-52 text-xl font-semibold">物件番号の記入</p>
          <div className="w-1/2 sp:w-4/5">
            <SelectBox
              required
              min={1}
              onChange={(e) => {
                setRoomNum(e);
              }}
              build={build}
            />
          </div>
        </div>
        <div>
          <Button
            size={100}
            className="w-80 h-14 sp:w-60 sp:h-10 xmin:w-32 xmin:h-8 rounded-none border border-solid border-black text-base"
            onClick={handleCheckout}
          >
            送信する
          </Button>
        </div>
      </div>
    </section>
  );
};

export default CheckOut;
