import React from "react"
import LinkBtn from "../components/linkBtn"

const Check = () => {
  return (
    <section className="bg-[#11013d]">
      <div className="w-full h-screen flex sp:flex-col justify-center items-center gap-8">
        <LinkBtn title="チェックイン" linkUrl="/checkin" />
        <LinkBtn title="チェックアウト" linkUrl="/checkout" />
      </div>
    </section>
  )
}

export default Check  



