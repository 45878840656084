import React from "react";
import { Link } from "react-router-dom"

const LinkBtn = (props) => {
  console.log(props)
  return(
    <Link to={props.linkUrl} className=" py-5 w-48 flex justify-center items-center border-none rounded-lg text-white text-xl shadow-lg bg-gradient-to-br from-purple-600 via-indigo-600 to-cyan-400">{props.title}</Link>
  )
  
}

export default LinkBtn;