import React, { useEffect, useState } from "react";
import CheckInInput from "../components/checkInInput";
import { Link, useNavigate } from "react-router-dom";
import InsertTable from "../components/insertTable";
import ImageUpload from "../components/imageUpload";
import axios from "axios";
import { BUILDING_ENDPOINT, CONTACT_ENDPOINT } from "../endpoint";
import pdf from "../terms-of-use/manekey_hotel_policy.pdf";
import { notification } from "antd";
import SelectBox from "../components/selectBox";
import DropBox from "../components/dropbox";

const customers = Array(20).fill().map((_, index) => index + 1);

const countryData = [
  "日本(Japan)",
  "韓国(South Korea)",
  "台湾(Taiwan)",
  "香港(Hong Kong)",
  "中国(China)",
  "タイ(Thailand)",
  "シンガポール(Singapore)",
  "マレーシア(Malaysia)",
  "インドネシア(Indonesia)",
  "フィリピン(Philippines)",
  "ベトナム(Vietnam)",
  "インド(India)",
  "英国(United Kingdom)",
  "ドイツ(Germany)",
  "フランス(France)",
  "イタリア(Italy)",
  "スペイン(Spain)",
  "ロシア(Russia)",
  "米国(United States)",
  "カナダ(Canada)",
  "オーストラリア(Australia)",
  "その他(Other)",
];

const job = [
  "会社員 (Company employee)",
  "会社役員 (Company executive)",
  "学生 (Student)",
  "主婦 (Housewife)",
  "その他 (Others)",
];

const CheckIn = () => {
  const [tableData, setTableData] = useState([]);
  const [memberNum, setMemberNum] = useState(1);
  const [roomNum, setRoomNum] = useState();
  const [checkInDate, setCheckInDate] = useState();
  const [checkInTime, setCheckInTime] = useState();
  const [checkOutDate, setCheckOutDate] = useState();
  const [checkOutTime, setCheckOutTime] = useState();
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [country, setCountry] = useState();
  const [address, setAddress] = useState();
  const [occupation, setOccupation] = useState();
  const [age, setAge] = useState();
  const [errors, setErrors] = useState({});
  const [faceImage, setFaceImage] = useState();
  const [passportImage, setPassportImage] = useState();
  const [api, contextHolder] = notification.useNotification();
  const [build, setBuild] = useState([]);
  const [resetImage, setResetImage] = useState(false);

  const [addNum, setAddNum] = useState(1);
  const [termService, setTermService] = useState(2); // 2: not Submitted, 0: false, 1: true

  let navigate = useNavigate();

  useEffect(() => {
    try {
      const getBuildingData = async () => {
        const res = await axios.get(BUILDING_ENDPOINT);
        setBuild(res.data);
        console.log(res.data.map((item) => item.roomNum));
      };
      getBuildingData();
    } catch (error) {
      console.log(error);
    }
  }, []);
  const formValidation = () => {
    const errors = {};
    if (!roomNum) {
      errors.roomNum = "物件番号を正確に入力してください。";
    }
    if (!checkInDate) {
      errors.checkInDate = "チェックイン日を正確に入力してください。";
    }
    if (!checkInTime) {
      errors.checkInTime = "チェックイン時間を正確に入力してください。";
    }
    if (!checkOutDate) {
      errors.checkOutDate = "チェックアウト日を正確に入力してください。";
    }
    if (!checkOutTime) {
      errors.checkOutTime = "チェックアウト時間を正確に入力してください。";
    }
    if (!fullName) {
      errors.fullName = "氏名を正確に入力してください。";
    }
    if (!email) {
      errors.email = "メールアドレスを正確に入力してください。";
    }
    if (!country) {
      errors.country = "国籍を正確に入力してください。";
    }
    if (!address) {
      errors.address = "住所を正確に入力してください。";
    }
    if (!occupation) {
      errors.occupation = "職業を正確に入力してください。";
    }
    if (!age) {
      errors.age = "年齢を正確に入力してください。";
    }
    console.log(errors);
    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const nextBtn = () => {
    setResetImage(true);
    const item = {
      memberNum: memberNum,
      roomNum: roomNum,
      faceImage: faceImage,
      passportImage: passportImage,
      checkInDate: checkInDate,
      checkInTime: checkInTime,
      checkOutDate: checkOutDate,
      checkOutTime: checkOutTime,
      fullName: fullName,
      email: email,
      country: country,
      address: address,
      occupation: occupation,
      age: age,
    };
    if (tableData.length >= addNum && addNum) {
      let modifiedData = [...tableData];
      modifiedData[addNum - 1] = item;
      setTableData(modifiedData);

      const num = addNum;
      if (tableData.length == addNum) {
        resetItem();
      } else {
        let attachNum = tableData[num];
        setCheckInDate(attachNum.checkInDate);
        setCheckInTime(attachNum.checkInTime);
        setCheckOutDate(attachNum.checkOutDate);
        setCheckOutTime(attachNum.checkOutTime);
        setFullName(attachNum.fullName);
        setEmail(attachNum.email);
        setCountry(attachNum.country);
        setAddress(attachNum.address);
        setOccupation(attachNum.occupation);
        setAge(attachNum.age);
      }
    } else {
      const newData = [...tableData, item];
      setTableData(newData);
      resetItem();
    }
    setAddNum(addNum + 1);
  };

  const resetItem = () => {
    setCheckInDate("");
    setCheckInTime("");
    setCheckOutDate("");
    setCheckOutTime("");
    setFullName("");
    setEmail("");
    setCountry(null);
    setAddress("");
    setOccupation(null);
    setAge("");
  };

  const handleNextBtn = (event) => {
    event.preventDefault();
    if (formValidation()) {
      nextBtn();
    }
  };

  const routeChange = () => {
    navigate("/");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const item = {
      memberNum: memberNum,
      roomNum: roomNum,
      checkInDate: checkInDate,
      checkInTime: checkInTime,
      checkOutDate: checkOutDate,
      checkOutTime: checkOutTime,
      fullName: fullName,
      faceImage: faceImage,
      passportImage: passportImage,
      email: email,
      country: country,
      address: address,
      occupation: occupation,
      age: age,
    };

    if (memberNum == 1) {
      tableData.push(item);
    }

    const formData = new FormData();
    tableData.map((item, key) => {
      // formData.append("length", tableData.length);
      formData.append("memberNum[]", item.memberNum);
      formData.append("roomNum[]", item.roomNum);
      formData.append("checkInDate[]", item.checkInDate);
      formData.append("checkInTime[]", item.checkInTime);
      formData.append("checkOutDate[]", item.checkOutDate);
      formData.append("checkOutTime[]", item.checkOutTime);
      formData.append("fullName[]", item.fullName);
      formData.append("email[]", item.email);
      formData.append("country[]", item.country);
      formData.append("address[]", item.address);
      formData.append("occupation[]", item.occupation);
      formData.append("age[]", item.age);
      formData.append("faceImage[]", item.faceImage);
      formData.append("passportImage[]", item.passportImage);
    });

    const submitFunc = async () => {
      try {
        const response = await axios.post(CONTACT_ENDPOINT, formData);
        routeChange();
      } catch (error) {
        openNotificationWithIcon("error");
      }
    };
    let res;
    if (memberNum !== 1) {
      if (termService == 1) {
        res = submitFunc();
      } else setTermService(0);
    } else if (formValidation()) {
      if (termService == 1) {
        res = submitFunc();
      } else setTermService(0);
    }
  };

  const handleEdit = () => {
    if (addNum > 0) {
      const num = addNum - 1;
      setAddNum(num);
      let attachNum = tableData[num - 1];
      setCheckInDate(attachNum.checkInDate);
      setCheckInTime(attachNum.checkInTime);
      setCheckOutDate(attachNum.checkOutDate);
      setCheckOutTime(attachNum.checkOutTime);
      setFullName(attachNum.fullName);
      setEmail(attachNum.email);
      setCountry(attachNum.country);
      setAddress(attachNum.address);
      setOccupation(attachNum.occupation);
      setAge(attachNum.age);
    }
  };

  const openNotificationWithIcon = (type) => {
    api[type]({
      message: "送信失敗エラー",
      description: "送信に失敗しました。 もう一度お試しください。",
    });
  };

  const face = async (e) => {
    setFaceImage(e);
  };

  const passport = async (e) => {
    setPassportImage(e);
  };

  return (
    <section className="max-w-[1000px] w-full m-auto px-3">
      {contextHolder}
      <div className="py-16 text-2xl font-bold lg:text-left text-center xmin:text-xl">
        Self Check-in / セルフチェックイン
      </div>
      <div className="w-full flex justify-between items-center lg:gap-20 gap-10 mb-10 md:flex-row flex-col">
        <div className="md:w-1/2 w-[90%]">
          <div className="w-full flex justify-between items-center text-lg">
            <div className="w-[100px] font-semibold xmin:text-sm">
              <div className="flex items-center gap-3">
                <p>人数の選択</p>
              </div>
              <p>(Customers)</p>
            </div>
            <div className="w-[60%]">
              <DropBox
                inputWidth
                value={memberNum}
                name={"memberNum"}
                option={customers}
                min={1}
                onchange={(e) => { setMemberNum(Number(e)) }} />
            </div>
          </div>
        </div>
        <div className="md:w-1/2 w-[90%]">
          <div className="w-full flex justify-between items-center text-lg">
            <div className="w-[140px] font-semibold xmin:text-sm">
              <div className="flex items-center gap-3">
                <p className=" w-12 h-10 flex justify-center items-center rounded-lg bg-[#545454] text-white text-sm sp:w-8 sp:h-7 sp:text-xs">
                  必須
                </p>
                <p>物件番号</p>
              </div>
              <p>(Room Number)</p>
            </div>
            <div className="w-[60%]">
              <SelectBox
                disabled={addNum !== 1}
                required
                error={errors}
                name="roomNum"
                min={1}
                value={roomNum}
                onchange={(e) => {
                  setRoomNum(Number(e));
                }}
                build={build}
              />
            </div>
          </div>
        </div>
      </div>
      {memberNum > 1 && (
        <div className="text-xs">
          <InsertTable tableData={tableData} />
        </div>
      )}
      <div className="mb-10 xmin:text-sm">
        <div className="md:p-10 p-4 flex flex-col gap-14 border border-black">
          <div className="flex justify-around items-center">
            <div>
              <ImageUpload
                onChange={face}
                restImage={resetImage}
                setResetImage={setResetImage}
                file={tableData[addNum - 1]?.faceImage}
              />
              <p className="text-center">本人の顔写真</p>
            </div>
            <div>
              <ImageUpload
                onChange={passport}
                restImage={resetImage}
                setResetImage={setResetImage}
                file={tableData[addNum - 1]?.passportImage}
              />
              <p className="text-center">パスポート写真</p>
            </div>
          </div>
          <div className="flex flex-col gap-14 xmin:gap-9 w-full sp:max-w-[400px] sp:w-full m-auto">
            <CheckInInput
              type="date"
              title="チェックイン日"
              name="checkInDate"
              subTitle="(Check-in Date)"
              required
              onChange={(e) => {
                setCheckInDate(e);
              }}
              value={checkInDate}
              error={errors}
            />
            <CheckInInput
              type="time"
              title="チェックイン時間"
              name="checkInTime"
              subTitle="(Check-in Time)"
              required
              onChange={(e) => {
                setCheckInTime(e);
              }}
              value={checkInTime}
              error={errors}
            />
            <CheckInInput
              type="date"
              title="チェックアウト日"
              name="checkOutDate"
              subTitle="(Check-out Date)"
              required
              onChange={(e) => {
                setCheckOutDate(e);
              }}
              value={checkOutDate}
              error={errors}
            />
            <CheckInInput
              type="time"
              title="チェックアウト時間"
              name="checkOutTime"
              subTitle="(Check-out Time)"
              required
              onChange={(e) => {
                setCheckOutTime(e);
              }}
              value={checkOutTime}
              error={errors}
            />
            <CheckInInput
              type="text"
              title="氏名"
              name="fullName"
              subTitle="(Full Name)"
              required
              onChange={(e) => {
                setFullName(e);
              }}
              value={fullName}
              error={errors}
              placeholder={`ゲスト${addNum}`}
            />
            <CheckInInput
              type="email"
              title="メールアドレス"
              name="email"
              subTitle="(Email)"
              required
              onChange={(e) => {
                setEmail(e);
              }}
              value={email}
              error={errors}
              placeholder={`ゲスト${addNum}`}
            />
            <div className="">
              <div className="w-full flex justify-between items-center text-lg sp:flex-col sp:gap-y-2 sp:items-start xmin:text-sm">
                <div className=" font-semibold sp:flex sp:justify-center sp:items-center">
                  <div className="flex items-center gap-3">
                    <p className=" w-12 h-10 flex justify-center items-center rounded-lg bg-[#545454] text-white text-sm sp:w-8 sp:h-7 sp:text-xs">
                      必須
                    </p>
                    <p>国籍</p>
                  </div>
                  <p>(Country)</p>
                </div>
                <div className="sp:w-full">
                  <DropBox
                    option={countryData}
                    value={country}
                    name={"country"}
                    error={errors}
                    placeholder={`ゲスト${addNum}`}
                    onchange={(e) => { setCountry(e) }} />
                </div>
              </div>
            </div>

            <CheckInInput
              type="text"
              title="住所"
              name="address"
              subTitle="(Address)"
              required
              onChange={(e) => {
                setAddress(e);
              }}
              value={address}
              error={errors}
              placeholder={`ゲスト${addNum}`}
            />
            <div className="">
              <div className="w-full flex justify-between items-center text-lg sp:flex-col sp:gap-y-2 sp:items-start xmin:text-sm">
                <div className=" font-semibold sp:flex sp:items-center">
                  <div className="flex items-center gap-3">
                    <p className=" w-12 h-10 flex justify-center items-center rounded-lg bg-[#545454] text-white text-sm sp:w-8 sp:h-7 sp:text-xs">
                      必須
                    </p>
                    <p>職業</p>
                  </div>
                  <p>(Occupation)</p>
                </div>
                <div className="sp:w-full">
                  <DropBox
                    option={job}
                    name={"occupation"}
                    error={errors}
                    value={occupation}
                    placeholder={`ゲスト${addNum}`}
                    onchange={(e) => { setOccupation(e) }} />
                </div>
              </div>
            </div>

            <CheckInInput
              type="number"
              title="年齢"
              name="age"
              subTitle="(Age)"
              required
              min={1}
              onChange={(e) => {
                setAge(e);
              }}
              value={age}
              error={errors}
              placeholder={`ゲスト${addNum}`}
            />
          </div>
          {memberNum > 1 && (
            <div className="flex justify-center gap-2">
              {addNum > 1 && (
                <button
                  className=" w-48 border border-solid border-black"
                  onClick={handleEdit}
                >
                  前の方/Previos person
                </button>
              )}
              {memberNum >= addNum && (
                <button
                  className="w-48 border border-solid border-black"
                  onClick={handleNextBtn}
                  disabled={
                    tableData.length >= memberNum && addNum > tableData.length
                  }
                >
                  次の方/Next person
                </button>
              )}
            </div>
          )}
        </div>
        {(memberNum == 1 || memberNum == tableData.length) &&
          <div className="mt-10 xmin:mt-6">
            <div className="flex justify-center items-center gap-2">
              <input
                value="service"
                type="checkbox"
                checked={termService == 1}
                onClick={() => {
                  setTermService((prev) => (prev == 1 ? 0 : 1));
                }}
              />
              <div className="sp:text-sm xmin:text-xs">
                <Link
                  to={pdf}
                  target="_blank"
                  className=" text-blue-700 underline"
                >
                  利用規約
                </Link>
                に同意します。
              </div>
            </div>
            {termService == 0 && (
              <div className="text-sm text-center text-red-500 ">
                <span className="text-xl">⚠︎</span>利用規約に同意してください。
              </div>
            )}
            <div className="mt-6 xmin:mt-3 w-full flex justify-center items-center">
              <button
                className="px-36 py-4 sp:px-28 sp:py-3 xmin:px-20 xmin:py-2 border border-solid border-black"
                onClick={handleSubmit}
              >
                送信する
              </button>
            </div>
          </div>

        }
      </div>
    </section>
  );
};

export default CheckIn;
