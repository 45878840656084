import { Select } from 'antd';
import React from 'react';

const DropBox = ({ option, onchange, value, error, inputWidth, min, placeholder, name }) => {
    const searchItem = option && option.map((item) => ({
        value: item,
        label: String(item),
    }));
    const handleChange = (e) => {
        onchange(e);
    }

    return (
        <div className='xmin:text-xs'>
            <Select
                showSearch
                // style={{ width: 400, }}
                className={inputWidth ? "w-full" : "w-[400px] sp:w-full text-xs"}
                placeholder={placeholder}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) => {
                    const labelA = optionA?.label ?? '';
                    const labelB = optionB?.label ?? '';
                    const numA = +labelA; 
                    const numB = +labelB; 
                    if (!isNaN(numA) && !isNaN(numB)) {
                      return numA - numB; 
                    }
                    return labelA.toLowerCase().localeCompare(labelB.toLowerCase());
                  }}
                onChange={handleChange}
                value={value}
                options={searchItem}
            />
            {error && <p className="ps-2 text-red-600 text-sm">{error[name]}</p>}
        </div>
    )
};
export default DropBox;