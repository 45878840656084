import React from "react";
import { Select } from "antd";

const SelectBox = ({ build, disabled, min, onchange, error, name }) => {
  const roomData = build.map((item) => ({
    value: item.roomNum,
    label: String(item.roomNum),
  }));
  const handleonChange = (e) => {
    onchange(e);
  };
  const onSearch = (value) => {
    console.log("search:", value);
  };

  return (
    <>
      <Select
        showSearch
        placeholder="※半角数字※"
        optionFilterProp="children"
        onChange={handleonChange}
        onSearch={onSearch}
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        // filterSort={(optionA, optionB) =>
        //   (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
        // }
        // filterSort={(optionA, optionB) => {
        //   const labelA = optionA?.label ?? '';
        //   const labelB = optionB?.label ?? '';
        //   const numA = +labelA; // Attempt to convert labelA to a number
        //   const numB = +labelB; // Attempt to convert labelB to a number

        //   // Check if both labels are numeric and not NaN
        //   if (!isNaN(numA) && !isNaN(numB)) {
        //     return numA - numB; // Compare as numbers
        //   }

        //   // Default to case-insensitive string comparison if not both numeric
        //   return labelA.toLowerCase().localeCompare(labelB.toLowerCase());
        // }}
        className="w-full h-20 border-none bg-[#eff1f5] rounded-md"
        disabled={disabled}
        min={min}
        options={roomData}
      />
      {error && <p className="ps-2 text-red-600 text-sm">{error[name]}</p>}
    </>
  );
};
export default SelectBox;
