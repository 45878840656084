import React, { useEffect, useState, useCallback } from "react";
import { Table } from 'antd'

const breakPoint = 768;

const columns = [
  {
    title: 'Room Number',
    dataIndex: 'image',
    key: 'image'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age'
  },
  {
    title: 'Country',
    dataIndex: 'country',
    key: 'country'
  },
  {
    title: 'Check-In Date',
    dataIndex: 'checkInDate',
    key: 'checkInDate'
  },
  {
    title: 'Check-In Time',
    dataIndex: 'checkInTime',
    key: 'checkInTime'
  },
  {
    title: 'Check-Out Date',
    dataIndex: 'checkOutDate',
    key: 'checkOutDate'
  },
  {
    title: 'Check-Out Time',
    dataIndex: 'checkOutTime',
    key: 'checkOutTime'
  },
];

const InsertTable = (props) => {
  const [saveTable, setSaveTable] = useState([]);
  const [windowWidth, setWindowWidth] = useState(0);
  const handleWindowResize = useCallback(event => {
    setWindowWidth(window.innerWidth);
}, []);

useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
        window.removeEventListener('resize', handleWindowResize);
    };
}, [handleWindowResize]);
  useEffect(() => {
    if (props.tableData.length > 0) {
      const data = props.tableData.map((item, key) => ({
        key,
        image: item.roomNum,
        name: item.fullName,
        age: item.age,
        country: item.country,
        checkInDate: item.checkInDate,
        checkInTime: item.checkInTime,
        checkOutDate: item.checkOutDate,
        checkOutTime: item.checkOutTime,
        description: `${windowWidth < breakPoint ? `チェックイン日: ${item.checkInDate}、 チェックイン時間: ${item.checkInTime}、 チェックアウト日: ${item.checkOutDate}、 チェックアウト時間: ${item.checkOutTime}、`: ''}住所: ${item.address}, 職業: ${item.occupation}`
      }));
      setSaveTable(data)
    }
  }, [props.tableData, windowWidth])

  return (
    <Table
      columns={windowWidth < breakPoint ? columns.filter((_, i) => i < 4) : columns}
      expandable={{
        expandedRowRender: (record) => (
          <p style={{ margin: 0, }}>{record.description}</p>
        ),
        rowExpandable: (record) => record.name !== 'Not Expandable',
      }}
      dataSource={saveTable}
    />
  )
}

export default InsertTable;