import React, { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Avatar, Upload } from 'antd';

const ImageUpload = (props) => {
  const [fileList, setFileList] = useState([]);
  const [imageUrl, setImageUrl] = useState(null);

  const handleReset = () => {
    setFileList([]);
    setImageUrl(null);
  };

  const handleFileUpload = async ({ file }) => {
    const url = URL.createObjectURL(file)
    setImageUrl(url);
    setFileList([file]);
    props.onChange(file);
  };
  useEffect(() => {
    if(props.restImage == true) {
      handleReset()
      props.setResetImage(false);
    }
  }, [props.restImage])

  useEffect(() => {
    if(props.file){
      const url = URL.createObjectURL(props.file)
      setImageUrl(url);
    }
  }, [props.file])
  return (
    <>
      <Upload
        onChange={handleFileUpload}
        beforeUpload={() => false}
        showUploadList={false}
      >
        <Button className='w-48 h-48 sp:w-40 sp:h-40 rounded-full p-0 text-2xl'>
          {imageUrl ? <Avatar src={imageUrl} className='w-full h-full' /> : '+' }
        </Button>
      </Upload>
    </>
  );
};
export default ImageUpload;